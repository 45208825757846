import request from '~/common/utils/request'

export function login (data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
export function login4g (data) {
  return request({
    url: '/user/login-4g',
    method: 'post',
    data
  })
}
export function register (data) {
  return request({
    url: '/user',
    method: 'post',
    data
  })
}

export function loginGoogle (data) {
  return request({
    url: '/user/social-login/google',
    method: 'post',
    data
  })
}

export function loginFacebook (data) {
  return request({
    url: '/user/social-login/facebook',
    method: 'post',
    data
  })
}

export function getInfo (token) {
  if (!token) {
    return request({
      url: '/profile/user',
      method: 'get'
    })
  } else {
    return request({
      url: '/profile/user',
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }
}

export function forgetPassword (mobile) {
  return request({
    url: '/user/forgotPassword',
    method: 'get',
    params: {
      mobile,
      platform: 'app'
    }
  })
}
