import request from '~/common/utils/request'

/**
 * Simple RESTful resource class
 */
class Resource {
  constructor (uri) {
    this.uri = uri
  }

  list (query = {}, token = null) {
    if (!token) {
      return request({
        url: '/' + this.uri,
        method: 'get',
        params: query
      })
    }
    return request({
      url: '/' + this.uri,
      method: 'get',
      params: query,
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  get (id, token = null) {
    if (!token) {
      return request({
        url: '/' + this.uri + '/' + id,
        method: 'get'
      })
    }
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
  }

  store (resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource
    })
  }

  update (id, resource) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource
    })
  }

  destroy (id) {
    return request({
      url: '/' + this.uri + '/' + id,
      method: 'delete'
    })
  }

  put (resource) {
    return request({
      url: '/' + this.uri,
      method: 'put',
      data: resource
    })
  }

  patch (resource) {
    return request({
      url: '/' + this.uri,
      method: 'patch',
      data: resource
    })
  }
}

export { Resource as default }
