import request from '~/common/utils/request'

export function sendVerifyMobile (data) {
  return request({
    headers: {
      'Content-Type': 'application/json'
    },
    url: '/user/social/mobile',
    method: 'put',
    data
  })
}

export function verifyOtp (data) {
  return request({
    url: '/user/verify/otp',
    method: 'post',
    data
  })
}

export function resendVerify (data) {
  return request({
    url: '/user/resend/otp',
    method: 'post'
  })
}
